@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  @layer utilities {
    body {
      @apply font-light text-neutral-900 lining-nums;
    }
  }
}